export function hexToRgba(hex='#fff', alpha = 1) {
  // Remove the '#' if it exists
  hex = hex.replace(/^#/, "");
  
  // Parse hex color components
  let r, g, b;
  if (hex.length === 3) {
    // Expand shorthand form (e.g., "03F" -> "0033FF")
    r = parseInt(hex[0] + hex[0], 16);
    g = parseInt(hex[1] + hex[1], 16);
    b = parseInt(hex[2] + hex[2], 16);
  } else if (hex.length === 6) {
    r = parseInt(hex.slice(0, 2), 16);
    g = parseInt(hex.slice(2, 4), 16);
    b = parseInt(hex.slice(4, 6), 16);
  } else {
    throw new Error("Invalid hex color format");
  }
  
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
