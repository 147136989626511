export const passOptions = {
  shouldForwardProp: (prop) =>
    ![
      "$color",
      "$colorOpacity",
      "$textColor",
      "$borderColor",
      "$active",
      "$size",
      "$thumb",
    ].includes(prop),
};
