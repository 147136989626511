import React, { useEffect } from "react";
import { useCloudCommands } from "../../context/CloudCommandsProvider";
import { RegularScreen } from "./RegularScreen";

export const DirectControlScreen = ({ _id, ...props }) => {
  const { getGroup } = useCloudCommands();
  useEffect(() => {
    getGroup({ groupID: _id }).catch(console.error);
  }, []);
  return <RegularScreen _id={_id} {...props} />;
};
