import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ScreenFactory from "../../coomponents/screen";

const ProgramPage = () => {
  const params = useParams();
  const group = useSelector(({ programs }) =>
    programs.interface.find((group) => group._id === params.groupID),
  );
  if (!group) return null;
  return <ScreenFactory {...group} />;
};

export { ProgramPage };
