import React from "react";
import { PROGRAM_TYPES } from "../../constant";
import { LinkBtn } from "./LinkBtn";
import { Command } from "./Command";
import { SwitchCommand } from "./Switch";
import { SliderCommand } from "./SliderCommand";
import { VideoButton } from "./VideoButton";
import { UploadButtons } from "./FileUploadButton";
import { FileButton } from "./FileButton";

const ButtonsMap = {
  [PROGRAM_TYPES.LINK]: LinkBtn,
  [PROGRAM_TYPES.COMMAND]: Command,
  [PROGRAM_TYPES.WITH_PARAMS]: Command,
  [PROGRAM_TYPES.DYNAMIC]: Command,
  [PROGRAM_TYPES.SWITCH]: SwitchCommand,
  [PROGRAM_TYPES.SLIDER]: SliderCommand,
  [PROGRAM_TYPES.VIDEO]: VideoButton,
  [PROGRAM_TYPES.DIRECT]: Command,
  [PROGRAM_TYPES.UPLOAD]: UploadButtons,
  [PROGRAM_TYPES.FILE]: FileButton,
};

const ButtonFactory = ({ type, ...props }) => {
  const Component = ButtonsMap[type];
  if (!Component) return null;
  return <Component type={type} {...props} />;
};

export default ButtonFactory;
