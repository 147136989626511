import React from "react";
import { BaseStyleButton } from "./BaseStyleButton";
import { useCloudCommands } from "../../context/CloudCommandsProvider";

export const Command = ({
  _id,
  groupID,
  name,
  active,
  color,
  textColor,
  colorOpacity,
  borderColor,
  size,
}) => {
  const { runProgram } = useCloudCommands();
  return (
    <BaseStyleButton
      variant={"outlined"}
      fullWidth
      size="large"
      onClick={() => runProgram({ groupID, programID: _id })}
      $borderColor={borderColor}
      $active={active}
      $color={color}
      $textColor={textColor}
      $colorOpacity={colorOpacity}
      $size={size}
    >
      {name}
    </BaseStyleButton>
  );
};
