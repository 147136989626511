import styled from "@emotion/styled";
import { Switch } from "@material-ui/core";
import { passOptions } from "../../utils/buttonsCustomeProps";
import { hexToRgba } from "../../utils/hexToRGBA";

export const StyledSwitch = styled(Switch, passOptions)`
  &.MuiSwitch-root {
    width: 88px;
    height: 31px;
    padding: 0;
  }
  .MuiSwitch-switchBase {
    top: -5px;
    left: -4px;
    color: ${({ $thumb }) => $thumb};
    &.Mui-checked {
      transform: translateX(55px);
    }
    &.Mui-checked + .MuiSwitch-track {
      opacity: 1;
      background-color: transparent;
    }
  }
  & .MuiSwitch-thumb {
    width: 23px;
    height: 23px;
  }
  & .MuiSwitch-track {
    opacity: 1;
    box-sizing: border-box;
    border-radius: 25px;
    background-color: transparent;
    border: solid 2px ${({ $borderColor }) => $borderColor};
  }
`;
