import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { fileManager } from "../dal/fileManager";
import {PROGRAM_TYPES} from "../constant";

const MOCK_UPLOAD = {
  name: "onRemove",
  size: "MD",
  color: "#393e64",
  borderColor: "#2fd9fe",
  textColor: "#ffffff",
  thumb: "#836ff2",
  colorOpacity: 1,
  type: PROGRAM_TYPES.WITH_PARAMS
};

const MOCK_FILE = {
  _id: "video1",
  name: "video1",
  textColor: "#00e00f",
  commands: [
    {
      name: "video1",
      uid: "xzorhkoxjpml",
      id: "video1",
      groupName: "CustomPlaylist",
    },
  ],
  borderColor: "#0279f7",
  type: "VIDEO",
  state: null,
  color: "#c40e0e",
  colorOpacity: 0.5,
  size: "MD",
  ext: ".mp4",
  isDynamic: true,
};

const FileManagerContext = React.createContext({});

export const FileManagerProvider = ({ children }) => {
  const { groupID } = useParams();
  const [removeCandidate, setRemoveCandidate] = useState(null);
  const managerInfo = useSelector(({ programs }) =>
    programs.interface.find((group) => group._id === groupID),
  );

  const styles = useMemo(() => {
    const { state:a, ...uploadingButtons } =
      managerInfo.programs[1] || MOCK_UPLOAD;
    const { state:b, ...fileButtons } = managerInfo.programs[2] || MOCK_FILE;
    return {
      uploadingButtons,
      fileButtons,
    };
  }, [managerInfo]);

  const { enqueueSnackbar } = useSnackbar();
  const [files, setFiles] = useState([]);
  const abortController = useRef(null);
  const [screenMessage, setScreenMessage] = useState(null);

  const [uploading, setUploading] = useState(false);
  const [percentage, setPercentage] = useState(0);

  const uploadFiles = useCallback(async ({ target }) => {
    const controller = new AbortController();
    abortController.current = controller;
    const files = target.files;

    if (!files.length) {
      enqueueSnackbar("Не выбраны файлы для загрузки", { variant: "warning" });
      return;
    }

    const fileChunks = Array.from(files).reduce((chunks, file, index) => {
      const chunkIndex = Math.floor(index / 3); // Split into groups of 3
      if (!chunks[chunkIndex]) chunks[chunkIndex] = [];
      chunks[chunkIndex].push(file);
      return chunks;
    }, []);

    for (const chunk of fileChunks) {
      const formData = new FormData();
      chunk.forEach((file) => {
        formData.append("files[]", file);
      });

      try {
        setUploading(true);
        target.value = "";
        await fileManager.post(`/files/${groupID}`, formData, {
          signal: controller.signal,
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            setPercentage(Math.floor(progressEvent.progress * 100));
          },
        });
        enqueueSnackbar("Файлы загружены успешно.", { variant: "success" });
        setUploading(false);
        setPercentage(0);
        getFiles().then((data) => {
          setFiles(data.list || []);
          setScreenMessage(data.error);
        });
      } catch (error) {
        enqueueSnackbar(error.response.data.message || error.message, {
          variant: "error",
        });
        setUploading(false);
        setPercentage(0);
      }
    }
  }, []);
  const getFiles = useCallback(async () => {
    try {
      const files = await fileManager.get(`/files/${groupID}`);
      return files.data;
    } catch (error) {
      enqueueSnackbar(`File manager ${managerInfo.name} - ${error.message}`, {
        variant: "error",
      });
      return [];
    }
  }, [managerInfo]);

  const refreshScreen = useCallback(async () => {
    const data = await getFiles();
    setFiles(data.list || []);
    setScreenMessage(data.error);
  }, [getFiles, setFiles, setScreenMessage]);

  const removeFile = useCallback(
    async (name) => {
      try {
        await fileManager.delete(`/files/${groupID}/${name}`);
      } catch (e) {
        enqueueSnackbar(e.message, { variant: "error" });
      } finally {
        refreshScreen();
        setRemoveCandidate(null);
      }
    },
    [groupID],
  );

  const cancelUpload = useCallback(() => {
    if (abortController.current) abortController.current.abort();
  }, [abortController.current]);

  useEffect(() => {
    getFiles().then((data) => {
      setFiles(data.list || []);
      setScreenMessage(data.error);
    });
  }, []);
  return (
    <FileManagerContext.Provider
      value={{
        files,
        screenMessage,
        uploading,
        percentage,
        uploadFiles,
        refreshScreen,
        removeFile,
        cancelUpload,
        styles,
        removeCandidate,
        setRemoveCandidate
      }}
    >
      {children}
    </FileManagerContext.Provider>
  );
};

export const useFileManager = () => useContext(FileManagerContext);
