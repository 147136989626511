import {
  CHANGE_ACTIVE_PROGRAM,
  CHANGE_PROGRAM_STATE,
  GET_PROGRAMS,
  RUN_PROGRAM,
} from "./programActions";
const initState = {
  title: "",
  interface: [],
};

export const programsReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PROGRAMS: {

      return {
        ...state,
        interface: payload,
      };
    }

    case RUN_PROGRAM: {
      const { groupID, programID } = payload;
      const newInterface = window.structuredClone(state.interface);
      const group = newInterface.find(({ _id }) => _id === groupID);
      group.activeProgram = programID;
      return { ...state, interface: newInterface };
    }

    case CHANGE_ACTIVE_PROGRAM: {
      const { groupID, programID } = payload;
      const newInterface = window.structuredClone(state.interface);
      const group = newInterface.find(({ _id }) => _id === groupID);
      group.activeProgram = programID;
      return { ...state, interface: newInterface };
    }

    case CHANGE_PROGRAM_STATE: {
      try {
        const { groupID, programID, value } = payload;
        const newInterface = window.structuredClone(state.interface);
        const group = newInterface.find(({ _id }) => _id === groupID);
        const program = group.programs.find(({ _id }) => _id === programID);
        program.state.value = value;
        return { ...state, interface: newInterface };
      } catch (e) {
        console.error(e);
        return { ...state };
      }
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
