import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useCloudCommands } from "../../context/CloudCommandsProvider";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { StyledSwitch } from "../common/CustomSwitch";
import { CustomLabel } from "../common/CustomLabel";
import { passOptions } from "../../utils/buttonsCustomeProps";

const Container = styled(Box, passOptions)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const Item = styled(Box, passOptions)``;

export const SwitchCommand = ({
  _id,
  state = {},
  name,
  groupID,
  color,
  colorOpacity,
  borderColor,
  textColor,
  thumb,
}) => {
  const [localState, setState] = useState(!!state.value);
  useEffect(() => {
    setState(!!state.value);
  }, [state.value]);
  const { runProgram } = useCloudCommands();
  return (
    <Container>
      <Item>
        <CustomLabel $textColor={textColor} form={_id}>
          {name}
        </CustomLabel>
      </Item>
      <Item>
        <StyledSwitch
          $thumb={thumb}
          $borderColor={borderColor}
          checked={localState}
          onChange={(event, checked) => {
            setState(checked);
            runProgram({
              groupID,
              programID: _id,
              params: [+checked],
            }).catch(() => setState(!checked));
          }}
          name={_id}
          color="primary"
        />
      </Item>
    </Container>
  );
};
