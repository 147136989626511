import React from 'react';
import {Box, Button, makeStyles, TextField, useTheme} from "@material-ui/core";
import * as Yup from "yup";
import {Formik} from "formik";
import {useDispatch} from "react-redux";
import {getAuth} from "../../store/appActions";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {fileManager} from '../../dal/fileManager';

const useStyle = makeStyles(theme=>({
    input: {
        boxShadow: `0 0 0 100px ${theme.palette.background.paper} inset !important`,
        "&:-webkit-autofill, &:-webkit-autofill:focus, :-webkit-autofill:hover": {
            boxShadow: `0 0 0 100px ${theme.palette.background.paper} inset !important`
        }
    }
}))




const AuthForm = () => {
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const classes = useStyle();
    const {t,i18n} = useTranslation();
    return (
        <Formik
            initialValues={{
                login: '',
                password: ''
            }}
            validationSchema={Yup
                .object()
                .shape({
                    login: Yup
                        .string()
                        .max(255)
                        .required(t("message.fieldReq")),
                    password: Yup
                        .string()
                        .max(255)
                        .required(t("message.fieldReq"))
                })}
            onSubmit={(values, {setStatus, setSubmitting}) => {
                fetch(`/api/user/auth`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Lang":i18n.language
                    },
                    body: JSON.stringify({
                        login: values.login,
                        password: values.password
                    })
                }).then(res => res.json())
                    .then(data => {
                        if (!data.accessToken) {
                            setStatus({success: false});
                            setSubmitting(false);
                            enqueueSnackbar(data.message, {variant: "error", autoHideDuration: 8000})
                            return
                        }
                        setSubmitting(true);
                        setSubmitting(false);
                        dispatch(getAuth(data));
                        fileManager.defaults.headers.common.Authorization = `Bearer ${data.accessToken}`;
                    }).catch((e) => {
                    setStatus({success: false});
                    setSubmitting(false);
                    enqueueSnackbar(e.message, {variant: "error"})
                })
            }}
        >
            {({errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values}) => (
                <form
                    noValidate
                    onSubmit={handleSubmit}
                >
                    <TextField
                        autoFocus
                        error={Boolean(touched.login && errors.login)}
                        fullWidth
                        helperText={touched.login && errors.login}
                        label={t("auth.user")}
                        margin="normal"
                        name="login"
                        onChange={handleChange}
                        type="text"
                        value={values.login}
                        variant="outlined"
                        autoComplete={"off"}
                        inputProps={{
                            className:classes.input
                        }}
                    />
                    <TextField
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        label={t("auth.pass")}
                        margin="normal"
                        name="password"
                        onChange={handleChange}
                        type="password"
                        value={values.password}
                        variant="outlined"
                        inputProps={{
                            className:classes.input
                        }}
                    />
                    <Box sx={{mt: 2}}>
                        <Button
                            color={"secondary"}
                            disabled={isSubmitting}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                        >
                            {t("auth.loginBtn")}
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
    );
};

export {AuthForm};
