import { Box, Slider } from "@material-ui/core";
import styled from "@emotion/styled";
import React from "react";
import {passOptions} from '../../utils/buttonsCustomeProps';
const StyledSlider = styled(Slider, passOptions)`
  padding: 15px 0 11px 0;
  box-sizing: border-box;
  & .MuiSlider-rail {
    background-color: transparent;
    opacity: 0;
  }

  & .MuiSlider-track {
    background-color: transparent;
    opacity: 0;
  }

  & .MuiSlider-thumb {
    width: 23px;
    height: 23px;
    margin-top: -11.5px;
    margin-left: -11.5px;
    color: ${({ $thumb }) => $thumb};
  }
    & .MuiSlider-valueLabel{
        & *{
            color: white;
            background-color: ${({ $thumb }) => $thumb};
        }
        left: calc(-50% - -6px);
    }
`;

const ItemSliderContainer = styled(Box, passOptions)`
  border: 2px solid ${({ $thumb }) => $thumb || "transparent"};
  border-radius: 25px;
  padding: 0 15px;
`;

export const CustomSlider = ({ thumb, ...props }) => (
  <ItemSliderContainer $thumb={thumb}>
    <StyledSlider $thumb={thumb} {...props} />
  </ItemSliderContainer>
);
