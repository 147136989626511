import { Typography } from "@material-ui/core";
import React from "react";
import styled from "@emotion/styled";
const Styled = styled(Typography,{
  shouldForwardProp: (prop) =>
    ![
      "$textColor",
    ].includes(prop),
})`
  font-size: 20px;
  color: ${({ $textColor='white' }) => $textColor};
`;

export const CustomLabel = ({ children, ...rest }) => (
  <Styled {...rest}>{children}</Styled>
);
