import React, { useEffect } from "react";
import { createContext, useCallback, useContext } from "react";
import { SocketContext } from "./ConnectionProvider";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import {
  changeActiveProgramAction,
  changeProgramStateAction, getGroupPrograms,
  getPrograms,
} from '../pages/Program/programActions';
import { Button } from "@material-ui/core";
import { toggleSideMenu } from "../store/appActions";
const CloudCommandsContext = createContext({
  runProgram: ({ groupID, programID, params }) => {},
});

export const CloudCommandsProvider = ({ children }) => {
  const { socket: connection } = useContext(SocketContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const projectUID = useSelector(({ app }) => app.projectUID);
  const isAutoMode = useSelector(({ app }) => app.isAutoMode);

  const snackbarAction = useCallback(
    (key) => (
      <Button
        onClick={() => {
          dispatch(toggleSideMenu());
          closeSnackbar(key);
        }}
      >
        {t("message.on")}
      </Button>
    ),
    [],
  );

  const runProgram = useCallback(
    async ({ groupID, programID, params }) => {
      if (!isAutoMode) {
        enqueueSnackbar(t("message.manualMode"), {
          variant: "warning",
          action: snackbarAction,
        });
        throw new Error(t("message.manualMode"));
      }
      if (!connection) return;

      const response = await connection.emitWithAck("runProgram", {
        projectUID,
        groupID,
        programID,
        params,
      });
      let message = response.message;
      if (message === "Программа запущена успешно.") {
        message = t("message.successRun");
      }
      if (message === "connected") {
        message = t("message.connected");
      }
      if (message === "await") {
        message = t("message.wait");
      }
      if (message.replace("не подключён", t("notConn")))
        enqueueSnackbar(message, { variant: response.status });
      if (response.status !== "success") throw new Error(message);
    },
    [connection, projectUID, isAutoMode],
  );

  const changeActiveProgram = useCallback(
    (groupID, programID) => {
      dispatch(changeActiveProgramAction({ groupID, programID }));
    },
    [dispatch],
  );

  const changeProgramState = useCallback(
    ({ groupID, programID, value }) => {
      dispatch(changeProgramStateAction({ groupID, programID, value }));
    },
    [dispatch],
  );

  const getGroups = useCallback(async () => {
    try {
      if (!connection) return null;

      const { status, message, data } = await connection.emitWithAck(
        "getPrograms",
        {
          projectUID,
        },
      );

      status === "success"
        ? dispatch(getPrograms(data))
        : enqueueSnackbar(message, { variant: status });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    }
  }, [connection, projectUID]);

  const getGroup = useCallback(
    async ({ groupID }) => {
      try {
        const { status, message, data } = await connection.emitWithAck(
          "getUIProgramGroup",
          {
            projectUID,
            groupID,
          },
        );
        
        status === "success"
          ? dispatch(getGroupPrograms(data))
          : enqueueSnackbar(message, { variant: status });
      } catch (e) {
        enqueueSnackbar(e.message, { variant: "error" });
      }
    },
    [connection, projectUID],
  );

  useEffect(() => {
    if (!connection) return;
    connection.on("programStateChange", changeProgramState);
    connection.on("activeProgramChange", changeActiveProgram);
    return () => {
      connection.off("programStateChange", changeProgramState);
      connection.off("activeProgramChange", changeActiveProgram);
    };
  }, [changeActiveProgram, connection]);

  return (
    <CloudCommandsContext.Provider value={{ runProgram, getGroups, getGroup }}>
      {children}
    </CloudCommandsContext.Provider>
  );
};

export const useCloudCommands = () => useContext(CloudCommandsContext);
