import styled from "@emotion/styled";
import { Button } from "@material-ui/core";
import { hexToRgba } from "../../utils/hexToRGBA";

export const BaseStyleButton = styled(Button, {
  shouldForwardProp: (prop) =>
    ![
      "$color",
      "$colorOpacity",
      "$textColor",
      "$borderColor",
      "$active",
      "$size",
    ].includes(prop),
})`
  height: ${({ $size = "MD" }) => ($size === "MD" ? "50px" : "130px")};
  background-color: ${({ $color, $colorOpacity = 1 }) =>
    hexToRgba($color, $colorOpacity)} !important;
  border: ${({ $borderColor }) => `2px solid ${$borderColor}`};
  box-shadow: ${({ $active, $borderColor }) =>
    $active ? ` 0px 0px 15px 3px ${$borderColor}` : "none"};
  border-radius: 16px;
  color: ${({ $textColor = "white" }) => $textColor};
`;
