import React from "react";
import { SCREEN_TYPES } from "../../constant";
import { Typography } from "@material-ui/core";
import { RegularScreen } from "./RegularScreen";
import { DirectControlScreen } from "./DirectControlScreen";
import { FileManagerScreen } from "./FileManagerScreen";
import { FileManagerProvider } from "../../context/FileMenagerProvider";

const ScreenMap = {
  [SCREEN_TYPES.REGULAR]: RegularScreen,
  [SCREEN_TYPES.DIRECT_CONTROL]: DirectControlScreen,
  [SCREEN_TYPES.FILE_MANAGER]: ({ ...props }) => (
    <FileManagerProvider>
      <FileManagerScreen {...props} />
    </FileManagerProvider>
  ),
};

const ScreenFactory = ({ type, ...props }) => {
  const Component = ScreenMap[type];
  if (!Component)
    return (
      <Typography color={"primary"}>
        Oooops... unsupported screen type {type}
      </Typography>
    );
  return <Component type={type} {...props} />;
};

export default ScreenFactory;
