import React, { useCallback, useRef } from "react";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import { BaseStyleButton } from "./BaseStyleButton";
import { useFileManager } from "../../context/FileMenagerProvider";

export const UploadButtons = ({
  disabled,
  multiple = true,
  borderColor,
  color,
  textColor,
  colorOpacity,
  size,
  name,
}) => {
  const { uploadFiles } = useFileManager();
  const inputRef = useRef(null);
  const onClick = useCallback(() => {
    const input = inputRef.current;
    if (!input) return;
    input.click();
  }, [inputRef.current]);
  return (
    <>
      <input
        ref={inputRef}
        accept="video/*"
        style={{ display: "none" }}
        id="icon-button-file"
        type="file"
        multiple={multiple}
        onChange={uploadFiles}
        disabled={disabled}
      />
      <BaseStyleButton
        style={{justifyContent:'space-between'}}
        disabled={disabled}
        variant={"outlined"}
        fullWidth
        size="large"
        onClick={onClick}
        $borderColor={borderColor}
        $active={false}
        $color={color}
        $textColor={textColor}
        $colorOpacity={colorOpacity}
        $size={size}
        endIcon={<CreateNewFolderIcon />}
      >
        {name}
      </BaseStyleButton>
    </>
  );
};
