import React from "react";
import { useHistory } from "react-router-dom";
import { Paths } from "../../router/routes";
import { BaseStyleButton } from "./BaseStyleButton";

export const LinkBtn = ({
  to,
  name,
  borderColor,
  active,
  color,
  textColor,
  colorOpacity,
  size,
}) => {
  const history = useHistory();
  return (
    <BaseStyleButton
      onClick={() => history.push(`${Paths.Programs}/${to}`)}
      variant={"outlined"}
      fullWidth
      $borderColor={borderColor}
      $active={active}
      $color={color}
      $textColor={textColor}
      $colorOpacity={colorOpacity}
      $size={size}
    >
      {name}
    </BaseStyleButton>
  );
};
