import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import ButtonFactory from "../buttons";

export const RegularScreen = ({
  _id,
  activeProgram,
  name,
  programs = [],
  main,
  settings={}
}) => {
    console.log(settings);
    return (
        <>
            {!main && (
                <Box mb={2}>
                    <Typography variant={"h4"} color={"primary"} align={"center"}>
                        {name}
                    </Typography>
                </Box>
            )}

            <Grid container spacing={2} justifyContent="center" alignItems="center">
                {programs.map((program) => (
                    <Grid item key={program._id} xs={12}>
                        <ButtonFactory
                            {...program}
                            groupID={_id}
                            active={activeProgram === program._id}
                            previewSrc={settings ? settings.previewSrc : undefined}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
)}
