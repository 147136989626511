import React, {createContext, useContext, useEffect, useRef, useState} from 'react';
import { Backdrop, CircularProgress, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import RefreshIcon from "@material-ui/icons/Refresh";

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    position: "relative",
    overscrollBehaviorY: "none",
    overflowX: "hidden",
    minHeight: "100vh",
    paddingTop: `calc(${theme.mixins.toolbar.minHeight * 1.2}px + env(safe-area-inset-top, 0))`,
    paddingBottom: `calc(${theme.mixins.toolbar.minHeight + 52}px + env(safe-area-inset-bottom, 0))`,
    "@media(min-width:768px)": {
      paddingTop: theme.mixins.toolbar.minHeight * 1.5,
      paddingBottom: 108,
    },
  },
}));

const MainViewContext = createContext({ current: undefined });

const MainView = ({ children }) => {
  const classes = useStyle();
  const background = useSelector(({ theme }) => theme.background);
  const mainViewRef = useRef(null);

  return (
    <Container
      ref={mainViewRef}
      style={{ backgroundColor: background }}
      maxWidth="xl"
      className={classes.root}
    >
      <Container maxWidth="md">
        <MainViewContext.Provider value={mainViewRef}>{children}</MainViewContext.Provider>
      </Container>
    </Container>
  );
};

const useMainView = () => {
    return useContext(MainViewContext);
}

export { MainView, useMainView };
