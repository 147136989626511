import { useFileManager } from "../../context/FileMenagerProvider";
import React, { useEffect, useMemo, useRef } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import { BaseStyleButton } from "./BaseStyleButton";
import styled from "@emotion/styled";
import { useMainView } from "../MainView";
import { handleIntersection } from "./VideoButton";

const StyledVideoTag = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledVideoTagContainer = styled.div`
  width:63px;
  height: 46px;
  border-radius: 16px;
  overflow: hidden;
`;

const SmallPreview = ({ projectUID, groupID, fileName }) => (
    <StyledVideoTagContainer>
      <StyledVideoTag
          data-src={`https://filestream.expolightcontrols.net/preview/${projectUID}/${groupID}/${fileName}`}
          loop
          muted
          playsInline
          preload={"auto"}
          crossOrigin={"anonymous"}
      />
    </StyledVideoTagContainer>

);

export const FileButton = ({
  fileName,
  state={ transfer: {}, preview: {} },
  borderColor,
  color,
  textColor,
  colorOpacity,
  size,
  groupID,
  projectUID,
}) => {
  const viewPortRef = useMainView();
  const elemRef = useRef(null);
  const { setRemoveCandidate } = useFileManager();
  const isDisableRemove = useMemo(() => {
    const { transfer, preview } = state;
    if (transfer.state === "remove pending") {
      return true;
    }
    if (preview.state === "remove pending") {
      return true;
    }
    return false;
  }, [state.transfer, state.preview]);

  useEffect(() => {
    const { current: viewPort } = viewPortRef;
    const { current: button } = elemRef;
    if (!button || !viewPort) return;
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "20% 0% 0% 0%",
      threshold: 0,
    });
    observer.observe(button);
    return () => observer.disconnect();
  }, [elemRef.current, viewPortRef.current]);
  return (
    <BaseStyleButton
      ref={elemRef}
      style={{ justifyContent: "space-between", padding: "2px 15px 2px 5px", textAlign: "left" }}
      disabled={isDisableRemove}
      variant={"outlined"}
      fullWidth
      size="large"
      onClick={() => setRemoveCandidate(fileName)}
      $borderColor={borderColor}
      $active={false}
      $color={color}
      $textColor={textColor}
      $colorOpacity={colorOpacity}
      $size={size}
      startIcon={
        <SmallPreview
          projectUID={projectUID}
          groupID={groupID}
          fileName={fileName}
        />
      }
      endIcon={<DeleteIcon />}
    >
      <span style={{flexGrow:1}}>{fileName}</span>
    </BaseStyleButton>
  );
};
