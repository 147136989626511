import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { getPrograms } from "./programActions";
import { useSnackbar } from "notistack";
import { SocketContext } from "../../context/ConnectionProvider";
import ButtonFactory from "../../coomponents/buttons";
import ScreenFactory from "../../coomponents/screen";
import { useCloudCommands } from "../../context/CloudCommandsProvider";

const buttonsSelector = ({ programs }) => {
  return programs.interface.find((group) => group.main) || null;
};

const MainScreen = () => {
  const mainGroup = useSelector(buttonsSelector);
  const { getGroups } = useCloudCommands();

  useEffect(() => {
    getGroups();
  }, [getGroups]);

  if (!mainGroup) return null;
  return <ScreenFactory {...mainGroup} />;
};

export { MainScreen };
