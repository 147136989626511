import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { useFileManager } from "../../context/FileMenagerProvider";
import { useSelector } from "react-redux";
import ButtonFactory from "../buttons";
import { PROGRAM_TYPES } from "../../constant";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { hexToRgba } from "../../utils/hexToRGBA";

const StyledDialog = styled(Dialog)`
  & .MuiPaper-rounded {
    border-radius: 16px;
  }
  & .MuiDialogTitle-root {
    padding: 16px 38px;
  }
  & .MuiDialogContent-root {
    padding: 16px 38px;
  }
`;

const UploadingProcessDialog = () => {
  const { uploading, percentage, cancelUpload, styles } = useFileManager();
  const { t } = useTranslation();
  return (
    <StyledDialog
      open={uploading}
      aria-labelledby="upload-dialog-title"
      aria-describedby="upload-dialog-description"
    >
      <DialogTitle id="upload-dialog-title">
        {t("fileManager.uploadMessage")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="upload-dialog-description">
          <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <LinearProgress variant="determinate" value={percentage} />
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2" color="textSecondary">{`${Math.round(
                percentage,
              )}%`}</Typography>
            </Box>
          </Box>
        </DialogContentText>
        <DialogActions>
          <RemoveDialogButtons
            size={"small"}
            $color={styles.uploadingButtons.color}
            $colorOpacity={styles.uploadingButtons.colorOpacity}
            $textColor={styles.uploadingButtons.textColor}
            $borderColor={styles.uploadingButtons.borderColor}
            variant={"outlined"}
            onClick={cancelUpload}
          >
            {t("fileManager.cancel")}
          </RemoveDialogButtons>
        </DialogActions>
      </DialogContent>
    </StyledDialog>
  );
};

const RemoveDialogButtons = styled(Button, {
  shouldForwardProp: (prop) =>
    ![
      "$color",
      "$colorOpacity",
      "$textColor",
      "$borderColor",
      "$active",
      "$size",
    ].includes(prop),
})`
  background-color: ${({ $color, $colorOpacity = 1, variant }) =>
    variant === "outlined"
      ? "transparent"
      : hexToRgba($color, $colorOpacity)} !important;
  border: ${({ $borderColor }) => `2px solid ${$borderColor}`};
  border-radius: 16px;
  color: ${({ $textColor = "white" }) => $textColor};
`;

const RemoveDialog = () => {
  const { removeCandidate, removeFile, setRemoveCandidate, styles } =
    useFileManager();
  const { t } = useTranslation();
  return (
    <StyledDialog
      open={!!removeCandidate}
      aria-labelledby="remove-dialog-title"
      aria-describedby="remove-dialog-description"
    >
      <DialogTitle
        id="remove-dialog-title"
        style={{ textAlign: "center", fontSize: "1.25rem", fontWeight: "bold" }}
      >
        {t("fileManager.removeHeader")}
        <Typography>{`"${removeCandidate}"`}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="remove-dialog-description"
          style={{
            textAlign: "center",
            fontSize: "0.93rem",
            fontWeight: "regular",
          }}
        >
          {t("fileManager.removeContent")}
        </DialogContentText>
        <DialogActions style={{ justifyContent: "center" }}>
          <RemoveDialogButtons
            size={"small"}
            $color={styles.uploadingButtons.color}
            $colorOpacity={styles.uploadingButtons.colorOpacity}
            $textColor={styles.uploadingButtons.textColor}
            $borderColor={styles.uploadingButtons.borderColor}
            variant={"outlined"}
            onClick={() => setRemoveCandidate(null)}
          >
            {t("fileManager.cancel")}
          </RemoveDialogButtons>
          <RemoveDialogButtons
            size={"small"}
            $color={styles.uploadingButtons.color}
            $colorOpacity={styles.uploadingButtons.colorOpacity}
            $textColor={styles.uploadingButtons.textColor}
            $borderColor={styles.uploadingButtons.borderColor}
            variant={"contained"}
            onClick={() => removeFile(removeCandidate)}
          >
            {t("fileManager.remove")}
          </RemoveDialogButtons>
        </DialogActions>
      </DialogContent>
    </StyledDialog>
  );
};

export const FileManagerScreen = ({ _id, main, name }) => {
  const projectUID = useSelector(({ app }) => app.projectUID);
  const { files, styles } = useFileManager();

  return (
    <>
      {!main && (
        <Box mb={4.5} mt={4}>
          <Typography variant={"h4"} color={"primary"} align={"center"}>
            {name}
          </Typography>
        </Box>
      )}
      <Box mb={4.5}>
        <ButtonFactory
          {...styles.uploadingButtons}
          type={PROGRAM_TYPES.UPLOAD}
        />
      </Box>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {files.map(({ size, ...file }) => (
          <Grid item key={file.fileName} xs={12}>
            <ButtonFactory
              {...styles.fileButtons}
              type={PROGRAM_TYPES.FILE}
              {...file}
              fileSize={size}
              groupID={_id}
              projectUID={projectUID}
            />
          </Grid>
        ))}
      </Grid>

      <UploadingProcessDialog />
      <RemoveDialog />
    </>
  );
};
