export const GET_PROGRAMS = "GET_PROGRAMS";
export const RUN_PROGRAM = "RUN_PROGRAM";
export const CHANGE_ACTIVE_PROGRAM = "CHANGE_ACTIVE_PROGRAM";
export const CHANGE_PROGRAM_STATE = "CHANGE_PROGRAM_STATE";
export const GET_GROUP_PROGRAMS = "GET_GROUP_PROGRAMS";

export const getPrograms = (payload) => ({
  type: GET_PROGRAMS,
  payload,
});

export const getGroupPrograms = (payload) => ({
  type: GET_GROUP_PROGRAMS,
  payload,
});

export const changeActiveProgramAction = (payload) => ({
  type: CHANGE_ACTIVE_PROGRAM,
  payload,
});

export const runProgram = (groupName, programId) => ({
  type: RUN_PROGRAM,
  payload: { groupName, programId },
});

export const changeProgramStateAction = ({groupID, programID, value})=>({
  type: CHANGE_PROGRAM_STATE,
  payload: {groupID, programID, value}
})
