import { Button, Typography } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { hexToRgba } from "../../utils/hexToRGBA";
import { useSelector } from "react-redux";
import { useCloudCommands } from "../../context/CloudCommandsProvider";
import { passOptions } from "../../utils/buttonsCustomeProps";
import { useMainView } from "../MainView";

export function handleIntersection(entries, observer) {
  entries.forEach((entry) => {
    const element = entry.target;
    const video = element.querySelector("video");
    if (entry.isIntersecting) {
      if (video.tagName === "VIDEO") {
        video.style.display = "inline-block";
        video.setAttribute("src", video.dataset.src);
        video.load();
        video.play();
      }
    } else {
      if (video.tagName === "VIDEO") {
        video.style.display = "none";
        video.pause();
        video.removeAttribute("src");
        video.load();
      }
    }
  });
}

const SubProgramBtn = styled(Button, passOptions)`
  height: ${({ $size = "MD" }) => ($size === "MD" ? "50px" : "130px")};
  border-radius: 16px;
  overflow: hidden;
  border: 2px solid ${({ $borderColor = "white" }) => $borderColor};
  background-color: ${({ $color, $colorOpacity }) =>
    hexToRgba($color, $colorOpacity)};
  & video {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: ${({ $active }) => ($active ? 1 : 0.6)};
  }
  & p {
    position: relative;
    z-index: 2;
    color: ${({ $textColor = "white" }) => $textColor};
  }
`;
export const VideoButton = ({
  _id,
  groupID,
  name,
  active,
  color,
  textColor,
  colorOpacity,
  borderColor,
  size,
  ext = ".mp4",
  previewSrc = "self",
}) => {
  const viewPortRef = useMainView();
  const elemRef = useRef(null);
  const projectUID = useSelector(({ app }) => app.projectUID);
  const previewGroup = previewSrc === "self" ? groupID : previewSrc;
  const { runProgram } = useCloudCommands();
  useEffect(() => {
    const { current: viewPort } = viewPortRef;
    const { current: button } = elemRef;
    if (!button || !viewPort) return;
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "20% 0% 0% 0%",
      threshold: 0,
    });
    observer.observe(button);
    return () => observer.disconnect();
  }, [elemRef.current, viewPortRef.current]);
  return (
    <SubProgramBtn
      ref={elemRef}
      variant={"outlined"}
      fullWidth
      $color={color}
      $colorOpacity={colorOpacity}
      $textColor={textColor}
      $borderColor={borderColor}
      $active={active}
      $size={size}
      onClick={() => {
        runProgram({ groupID, programID: _id }).catch(console.error);
      }}
    >
      <video
        data-src={`https://filestream.expolightcontrols.net/preview/${projectUID}/${previewGroup}/${name + ext}`}
        loop
        muted
        playsInline
        preload={"auto"}
        crossOrigin={"anonymous"}
      />
      <Typography>{name}</Typography>
    </SubProgramBtn>
  );
};
