import React from "react";
import { useEffect, useState } from "react";
import { useCloudCommands } from "../../context/CloudCommandsProvider";
import styled from "@emotion/styled";
import { Box } from "@material-ui/core";
import { CustomSlider } from "../common/CustomSlider";
import { CustomLabel } from "../common/CustomLabel";
import { hexToRgba } from "../../utils/hexToRGBA";
import {passOptions} from '../../utils/buttonsCustomeProps';

const Container = styled(Box,passOptions)`
  padding: 12px 16px 35px 16px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  border-radius: 16px;
  background-color: ${({ $color, $colorOpacity }) =>
    hexToRgba($color, $colorOpacity)};
  border: solid 2px ${({ $borderColor }) => $borderColor || "transparent"};
`;

const Item = styled(Box)``;

export const SliderCommand = ({
  _id,
  state = {},
  name,
  groupID,
  color,
  textColor,
  colorOpacity,
  borderColor,
  thumb,
}) => {
  const [localState, setState] = useState(state.value);
  useEffect(() => {
    setState(state.value);
  }, [state.value]);
  const { runProgram } = useCloudCommands();
  return (
    <Container
      $borderColor={borderColor}
      $color={color}
      $colorOpacity={colorOpacity}
    >
      <Item style={{ textAlign: "center" }}>
        <CustomLabel $textColor={textColor}>{name}</CustomLabel>
      </Item>
      <Item style={{ marginTop: "17px" }}>
        <CustomSlider
          thumb={thumb}
          size="small"
          aria-labelledby="discrete-slider"
          valueLabelDisplay="auto"
          value={localState}
          step={state.step}
          min={state.min}
          max={state.max}
          track={false}
          onChange={(event, value) => setState(value)}
          onChangeCommitted={() =>
            runProgram({
              groupID,
              programID: _id,
              params: [+localState],
            }).catch(() => setState(state.value))
          }
        />
      </Item>
    </Container>
  );
};
