export const PROGRAM_TYPES = {
  COMMAND: "COMMAND",
  DYNAMIC: "DYNAMIC",
  LINK: "LINK",
  SWITCH: "SWITCH",
  SLIDER: "SLIDER",
  VIDEO: "VIDEO",
  DIRECT: "DIRECT",
  UPLOAD: "UPLOAD",
  FILE: "FILE",
  WITH_PARAMS: "WITH_PARAMS",
};

export const SCREEN_TYPES = {
  REGULAR: "REGULAR",
  DIRECT_CONTROL: "DIRECT_CONTROL",
  FILE_MANAGER: "FILE_MANAGER",
};


export const BUTTON_SIZE = {
  MD:'MD',
  LG:'LG',
}

export const noBackground = [PROGRAM_TYPES.SWITCH];
export const noSize = [PROGRAM_TYPES.SWITCH, PROGRAM_TYPES.SLIDER];
